<template>
  <header class="page__header">
    <section class="header__container">
      <div class="header__left">
        <button class="header__back-button" @click="handleGoBack()">
          <el-icon><Back /></el-icon>
        </button>
        <nav class="header__nav">
          <ul class="nav__box">
            <slot v-if="$route.meta.menu">
              <li>{{ $route.meta.menu }}</li>
            </slot>
            <slot v-else-if="$route.params.roomId && subjectStore.subject_title && !props.groupInfoVisible">
              <li>[글감] {{ subjectStore.subject_title }}</li>
            </slot>
            <slot v-else-if="$route.params.roomId && groupStore.group_title">
              <li>[그룹] {{ groupStore.group_title }}</li>
            </slot>
          </ul>
        </nav>
      </div>
      <div class="header__right">
        <button class="header__menu-button" @click="handleVisibleMenu()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" width="1.5rem" height="1.5rem"><path d="M21 7H3c-.6 0-1-.4-1-1s.4-1 1-1h18c.6 0 1 .4 1 1s-.4 1-1 1m1 5c0 .6-.333 1-.833 1H2.833c-.5 0-.833-.4-.833-1s.333-1 .833-1h18.334c.5 0 .833.4.833 1m-1.111 5c.667 0 1.111.4 1.111 1s-.444 1-1.111 1H3.11C2.444 19 2 18.6 2 18s.444-1 1.111-1H20.89z"></path></svg>
        </button>
      </div>
    </section>
  </header>
  <el-drawer v-model="menuVisible" :with-header="false" direction="rtl" class="aside-overlay">
    <div class="aside-wrap">
      <div class="aside-wrap__header">
        <div class="aside-wrap__title">
          <img src="@/assets/img/logo.svg" @click="handlePushRouter('index')">
          <NuxtLink :to="{ name: 'contact' }">{{ licenseProductName }}</NuxtLink>
          <!-- <NuxtLink v-if="authStore.capability.value === 'starter'" :to="{ name: 'contact' }">{{ licenseProductName }}</NuxtLink> -->
        </div>
        <div>
          <el-button text :icon="Close" @click="handleVisibleMenu()" />
        </div>
      </div>
      <div class="aside-wrap__menu">
        <el-menu :default-active="$route.name">
          <el-menu-item index="room">
            <NuxtLink :to="{ name: 'room' }" @click="handleVisibleMenu()">내 그룹</NuxtLink>
          </el-menu-item>
          <el-sub-menu v-if="$route.params.roomId" index="2">
            <template #title>{{ groupStore.group_title || '그룹' }}</template>
            <el-menu-item index="room-roomId">
              <NuxtLink :to="{ name: 'room-roomId', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">그룹 홈</NuxtLink>
            </el-menu-item>
            <slot v-if="groupStore.isOwner || authStore.isAdmin">
              <el-menu-item index="room-roomId-activity">
                <!-- <NuxtLink :to="{ name: 'room-roomId-activity', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">활동 및 글감 관리</NuxtLink> -->
                <NuxtLink :to="{ name: 'room-roomId-activity', params: { roomId: $route.params.roomId }, query: {activity:groupStore.activity_no, subject: groupStore.subject_no} }" @click="handleVisibleMenu()">활동 및 글감 관리</NuxtLink>

              </el-menu-item>
              <el-menu-item index="room-roomId-member">
                <NuxtLink :to="{ name: 'room-roomId-member', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">참여자 관리</NuxtLink>
              </el-menu-item>
              <el-menu-item index="room-roomId-analytics">
                <NuxtLink :to="{ name: 'room-roomId-analytics', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">그룹 통계</NuxtLink>
              </el-menu-item>
              <el-menu-item index="room-roomId-form">
                <NuxtLink :to="{ name: 'room-roomId-form', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">그룹 설정</NuxtLink>
              </el-menu-item>
            </slot>
            <slot v-else-if="groupStore.isMember">
              <el-menu-item index="room-roomId-analytics">
                <NuxtLink :to="{ name: 'room-roomId-analytics', params: { roomId: $route.params.roomId } }" @click="handleVisibleMenu()">그룹 통계</NuxtLink>
              </el-menu-item>
              <el-menu-item index="2-6">
                <a href="javascript:void(0)" @click="handleWithdrawGruop()">그룹 탈퇴</a>
              </el-menu-item>
            </slot>
          </el-sub-menu>
          <el-menu-item index="room-square">
            <NuxtLink :to="{ name: 'room-square' }" @click="handleVisibleMenu()">그룹 광장</NuxtLink>
          </el-menu-item>
          <el-menu-item index="room-my-posts">
            <NuxtLink :to="{ name: 'room-my-posts' }" @click="handleVisibleMenu()">내 글 모아보기</NuxtLink>
          </el-menu-item>
          <el-sub-menu index="4">
            <template #title>디지털 책장</template>
            <el-menu-item index="4-1">
              <a href="https://book.jajakjajak.com/digital-books" target="_blank">
                내 책장
                <ClientOnly>
                  <font-awesome-icon :icon="faArrowUpRightFromSquare" style="font-size: 0.85em; margin-left: 5px; vertical-align: middle;" />
                </ClientOnly>
              </a>
            </el-menu-item>
            <el-menu-item index="4-2">
              <a href="https://book.jajakjajak.com/digital-book-making" target="_blank">
                디지털 책 만들기
                <ClientOnly>
                  <font-awesome-icon :icon="faArrowUpRightFromSquare" style="font-size: 0.85em; margin-left: 5px; vertical-align: middle;" />
                </ClientOnly>
              </a>
            </el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="5">
            <template #title>고객센터</template>
            <el-menu-item index="5-1">
              <a type="text" @click="handleChanneTalk">고객센터 연결</a>
            </el-menu-item>
            <el-menu-item index="5-2">자작자작 알아보기</el-menu-item>
            <el-menu-item index="5-4">
              <a href="javascript:void(0)" @click="handleVisibleTerms()">서비스 이용약관</a>
            </el-menu-item>
            <el-menu-item index="5-5">
              <a href="javascript:void(0)" @click="handleVisiblePrivacyTerms()">개인정보 처리방침</a>
            </el-menu-item>
          </el-sub-menu>
          <!--
          <el-sub-menu v-if="authStore.user_classify === 'admin'" index="6">
            <template #title>관리자</template>
            <el-menu-item index="6-1">멤버십</el-menu-item>
            <el-menu-item index="6-2">사용량/요금</el-menu-item>
          </el-sub-menu>
          -->
          <slot v-if="authStore.isLogin">
            <el-sub-menu index="7">
              <template #title>마이페이지</template>
              <el-menu-item index="mypage-profile">
                <NuxtLink :to="{ name: 'mypage-profile' }" @click="handleVisibleMenu()">계정 프로필</NuxtLink>
              </el-menu-item>
              <el-menu-item index="mypage-security">
                <NuxtLink :to="{ name: 'mypage-security' }" @click="handleVisibleMenu()">암호변경</NuxtLink>
              </el-menu-item>
              <el-menu-item index="7-4">
                <a href="javascript:void(0)" @click="handleLogout()">로그아웃</a>
              </el-menu-item>
            </el-sub-menu>
          </slot>
          <el-menu-item index="contact">
            <NuxtLink :to="{ name: 'contact' }">가격안내</NuxtLink>
          </el-menu-item>
          <el-menu-item index="9">
            <a href="https://teampl100.notion.site/ae318e93c65a48fda2055d202b19ab8f" target="_blank">
              제품안내
              <ClientOnly>
                <font-awesome-icon :icon="faArrowUpRightFromSquare" style="font-size: 0.85em; margin-left: 5px; vertical-align: middle;" />
              </ClientOnly>
            </a>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </el-drawer>
  <el-dialog v-model="termsVisible" width="900px">
    <Terms />
  </el-dialog>
  <el-dialog v-model="privacyTermsVisible" width="900px">
    <TermsPrivacy />
  </el-dialog>
</template>

<script setup lang="ts">
import { deleteMember } from "~/composables/php/group";
import { Close, Back } from '@element-plus/icons-vue';
import { userLogout } from '@/composables/php/auth';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
  groupInfoVisible: {
    default: true,
  },
})

const router = useRouter();

const route = useRoute();

const menuVisible = ref(false);

const authStore = useAuthStore();

const termsVisible = ref(false);

const privacyTermsVisible = ref(false);

const licenseProductName = computed(() => {
  return authStore.licenseProductName;
});

const groupStore = useGroupStore();

const subjectStore = useSubjectStore();

function handleVisibleMenu() {
  menuVisible.value = !menuVisible.value;
}

function handleChanneTalk() {
  showChannelTalk();
  openChat();
}

// function handleLogout() {
//   handleVisibleMenu();
//   accessToken.value = null;
//   useAuthStore().resetUser();
//   useRouter().push({ name : 'index' });
// }

async function handleLogout() {
  handleVisibleMenu();

  await userLogout().then(() => {
    authStore.resetUser();
    useRouter().push({ name : 'index' });
  }).catch((e) => {
    const message = e.response && e.response.data && e.response.data.message || e.message;
      ElMessage.error(message || '알 수 없는 오류가 발생하였습니다.')
  });
}


function handlePushRouter(name: string) {
  useRouter().push({ name });
  handleVisibleMenu();
}

function handleVisibleTerms() {
  termsVisible.value = !termsVisible.value;
}

function handleVisiblePrivacyTerms() {
  privacyTermsVisible.value = !privacyTermsVisible.value;
}

function groupWithdrawValidator(val: any) {
  if (val.trim() === groupStore.group_title.trim()) {
    return true;
  }
  return false;
}

function handleWithdrawGruop() {
  handleVisibleMenu();
  ElMessageBox.prompt(
    h('div', null, [
      h('p', null, '정말로 그룹에서 탈퇴하시겠습니까?'),
      h('p', null, '탈퇴하려면 아래 입력 창에 그룹 이름을 입력해 주세요.'),
    ]),
    '그룹탈퇴',
    {
      confirmButtonText: '네, 탈퇴할래요.',
      cancelButtonText: '아니오.',
      inputErrorMessage: `탈퇴하는 그룹명을 정확히 입력해 주세요.`,
      inputValidator: groupWithdrawValidator,
    },
  ).then(() => {
    deleteMember(route.params.roomId, authStore.user_login).then((res) => {
      ElMessage.success('그룹에서 탈퇴처리 되었습니다.');
      useRouter().push({ name: 'room' });
    }).catch((e) => {
      const message = e.response && e.response.data && e.response.data.message || e.message;
      ElMessage.error(message || '알 수 없는 오류가 발생하였습니다.')
    });
  });
}

function handleGoBack() {
  if (route.name === 'room-roomId') {
    if (route.query.activityNo && route.query.subjectNo) {
      router.push({ name: 'room-roomId', query: {} });
      return;
    }
    router.push({ name: 'room' });
    return
  }
  if (route.name === 'room') {
    router.push({ name: 'index' });
    return;
  }
  if (route.name === 'room-roomId-own') {
    router.push({ name: 'room-roomId', query: {} });
    return;
  }
  if (route.name === 'room-roomId-activity') {
    router.push({ name: 'room-roomId', query: {activityNo:groupStore.activity_no, subjectNo: groupStore.subject_no} });
    return;
  }
  if (window.history.length > 1) {
    router.back();
  }
}
</script>

<style lang="scss">
@import '@/assets/css/layouts/common/header.scss';
</style>
